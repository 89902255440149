<template>
    <div style="width:100vw;height:100vh ">
       <sort-chart></sort-chart>
    </div>
</template>
<script >
import SortChart from "./login/SignForm.vue";
export default {
    components:{
        SortChart
    }
}
</script>